import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import PatchHeader from '../components/PatchHeader';
export const _frontmatter = {
  "title": "Meet the Flexbox Inspector",
  "date": "2019-05-09",
  "promo": "flexbox",
  "description": "Flexbox just got a whole lot easier to work with.",
  "color": "#d1593a"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Flexbox just got a whole lot easier to work with.`}</em></p>
    <p>{`CSS Grid is the new kid on the block, bringing with it many `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`shiny new tools`}</a>{` that make layout on the web easier. But we still need Flexbox. There are many layouts you can only accomplish with Flexbox. Mastering it will `}<a parentName="p" {...{
        "href": "/post/what-mastering-flexbox-will-do-for-you/"
      }}>{`do a lot for you`}</a>{` as a web dev. The real magic comes from knowing both Grid and Flexbox like the back of your hand. But working with Flexbox hasn't been easy.`}</p>
    <h2>{`The Flexbox Pit of Despair`}</h2>
    <p>{`So many variables go into the final Flexbox layout calculation: the size of the flex parent container, the `}<inlineCode parentName="p">{`flex-direction`}</inlineCode>{` orientation, items' starting `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, their `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode>{` ratios, whether or not you're using `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` etc. An expert level understanding of Flexbox was required to know how elements in the layout ended up where they did.`}</p>
    <p>{`When CSS Grid entered the scene, one could argue that it's a `}<em parentName="p">{`simpler`}</em>{` layout tool. For many layouts that's true, but Grid has its own complexity and set of variables and mechanisms to learn: `}<inlineCode parentName="p">{`implicit`}</inlineCode>{` tracks, the complex `}<a parentName="p" {...{
        "href": "/post/grid-item-placement/"
      }}>{`grid item placement`}</a>{` algorithm, `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines/"
      }}>{`named grid lines`}</a>{`, `}<inlineCode parentName="p">{`auto-flow`}</inlineCode>{`, `}<inlineCode parentName="p">{`gaps`}</inlineCode>{`, etc. What made Grid more approachable wasn't just the tech, but also the `}<a parentName="p" {...{
        "href": "/post/invisible-grid/"
      }}>{`Grid DevTools`}</a>{` that Mozilla shipped for working with it. That tool is what convinced me to switch to using `}<a parentName="p" {...{
        "href": "/post/firefox-for-layout/"
      }}>{`Firefox for layout`}</a>{`. But Flexbox was still a black box... until now.`}</p>
    <h2>{`New Flexbox DevTools`}</h2>
    <p>{`The good news is `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/developer/"
      }}>{`Firefox`}</a>{` massively improved the Flexbox development experience with some brand new DevTools. Here are some of the helpful things it gives you:`}</p>
    <h3>{`Flexbox Highlighter`}</h3>
    <p>{`You can overlay a highlighter to see the flex container and its flex items. Here's my blog header that uses Flexbox, with the DevTools highlighter showing its structure:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox-inspector/flex-highlight.jpg",
        "alt": "flexbox highlighter"
      }}></img></p>
    <h3>{`Flex Item Tool`}</h3>
    <p>{`The hardest part about working with Flexbox is trying to grok how flex items end up having the `}<em parentName="p">{`final`}</em>{` size that they do. Items' content size, width, min-width, max-width, flex-basis, grow and shrink settings all play a part. `}<a parentName="p" {...{
        "href": "/post/the-difference-between-width-and-flex-basis/"
      }}>{`The complete formula`}</a>{` is very much worth learning. But the new Flexbox Inspector takes the guesswork out of it, showing you exactly the size an item starts out at, its final size, and how it got there.`}</p>
    <p>{`Here's a goo zombie in `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` that grows as the flex container grows. And now the new DevTools shows us exactly what's happening! This little goo zombie starts out at `}<inlineCode parentName="p">{`136px`}</inlineCode>{` but gains `}<inlineCode parentName="p">{`+272px`}</inlineCode>{` because of its `}<inlineCode parentName="p">{`flex-grow: 1`}</inlineCode>{` setting, giving it a final size of `}<inlineCode parentName="p">{`408px`}</inlineCode>{` when the container is fully expanded. Having this level of insight into Flexbox feels like cheating!`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox-inspector/flex-item-tool.gif",
        "alt": "flex item tool"
      }}></img></p>
    <p>{`Shoutout to `}<a parentName="p" {...{
        "href": "https://twitter.com/violasong"
      }}>{`Victoria Wang`}</a>{`, who `}<a parentName="p" {...{
        "href": "https://hacks.mozilla.org/2019/01/designing-the-flexbox-inspector/"
      }}>{`designed the Flexbox Inspector`}</a>{`, for figuring out an incredibly clean way to present this helpful info.`}</p>
    <h3>{`And More`}</h3>
    <p>{`The DevTools team is working on `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Tools/Page_Inspector/How_to/Examine_Flexbox_layouts"
      }}>{`even more Flexbox features`}</a>{` to help out while you code your Flexbox layout. Huge thanks to those awesome folks for working so hard to make our CSS lives easier!`}</p>
    <h2>{`Practice with the DevTools Challenges!`}</h2>
    <p>{`I wish this had been available when I was first learning Flexbox! To celebrate the existence of such a fantastic new tool, I've created a handful of challenges you can try to solve using nothing but the DevTools:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flexbox-inspector/challenges.jpg",
        "alt": "flexbox challenges"
      }}></img></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/1"
          }}>{`challenge 1`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{` and `}<inlineCode parentName="p">{`flex-direction`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/2"
          }}>{`challenge 2`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`justify-content`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/3"
          }}>{`challenge 3`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`align-items`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/4"
          }}>{`challenge 4`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`align-self`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/5"
          }}>{`challenge 5`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`flex-grow`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/6"
          }}>{`challenge 6`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`flex-shrink`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/7"
          }}>{`challenge 7`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`flex-basis`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/8"
          }}>{`challenge 8`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`order`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/9"
          }}>{`challenge 9`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/10"
          }}>{`challenge 10`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`align-content`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://gedd.ski/flexbox/challenge/11"
          }}>{`challenge 11`}</a>{` `}{` `}{` `}<inlineCode parentName="p">{`flex shorthand`}</inlineCode></p>
      </li>
    </ul>
    <p>{`Each challenge corresponds to what you can learn in a chapter of `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{`, a free Flexbox Mastery Game. So if you get stuck, go play the game to learn what you're missing, then come back and solve the challenge.`}</p>
    <p>{`Happy hunting!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      